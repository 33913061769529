import { orderBy } from 'lodash';
import {
  FETCH_EMPLOYEES_BEGIN,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEES_FAILURE,
  
  FETCH_PASSWORD_RULES_BEGIN,
  FETCH_PASSWORD_RULES_SUCCESS,
  FETCH_PASSWORD_RULES_FAILURE,

  FETCH_ROLES_BEGIN,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILURE,

  FETCH_ROLE_POLICIES_BEGIN,
  FETCH_ROLE_POLICIES_SUCCESS,
  FETCH_ROLE_POLICIES_FAILURE,

  FETCH_POLICIES_BEGIN,
  FETCH_POLICIES_SUCCESS,
  FETCH_POLICIES_FAILURE,
 
  ADD_EDIT_EMPLOYEE_BEGIN,
  ADD_EDIT_EMPLOYEE_SUCCESS,
  ADD_EDIT_EMPLOYEE_FAILURE,

  ADD_EDIT_ROLE_BEGIN,
  ADD_EDIT_ROLE_SUCCESS,
  ADD_EDIT_ROLE_FAILURE,
 
  DELETE_EMPLOYEE_BEGIN,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,

  DELETE_ROLE_BEGIN,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,

  RESET_PASSWORD_SELF_BEGIN,
  RESET_PASSWORD_SELF_SUCCESS,
  RESET_PASSWORD_SELF_FAILURE,
  
} from 'redux/constants/EmployeeMgr';

const initState = {
  employees: [],
  passwordRules: [],
  roles: {},
  rolePolicies: [],
  policies: [],

  resetPasswordSelfLoading: false,
  resetPasswordSelfError: null
}

const employee = (state = initState, action) => {
	switch (action.type) {
		case FETCH_EMPLOYEES_BEGIN:
			return {
				...state,
				loading: true,
				employees: []
			}
		case FETCH_EMPLOYEES_SUCCESS:
			let sortedEmployees = orderBy(action.employees, ['ID'])
			return {
				...state,
                loading: false,
				employees: sortedEmployees
			}
        case FETCH_EMPLOYEES_FAILURE:
            return {
                ...state,
                loading: false
            }
		case FETCH_PASSWORD_RULES_BEGIN:
			return {
				...state,
				loading: true,
				passwordRules: []
			}
		case FETCH_PASSWORD_RULES_SUCCESS: 
			return {
				...state,
                loading: false,
				passwordRules: action.passwordRules
			}
        case FETCH_PASSWORD_RULES_FAILURE:
            return {
                ...state,
                loading: false
            }
		case FETCH_ROLES_BEGIN:
			return {
				...state,
				loading: true,
				roles: {}
			}
		case FETCH_ROLES_SUCCESS: 
			return {
				...state,
				loading: false,
				roles: action.roles
			}
		case FETCH_ROLES_FAILURE:
			return {
				...state,
				loading: false
			}
		case FETCH_ROLE_POLICIES_BEGIN:
			return {
				...state,
				loading: true,
				rolePolicies: [...state.rolePolicies, action.rolePolicies]
			}
		case FETCH_ROLE_POLICIES_SUCCESS: 
			return {
				...state,
				loading: false,
				rolePolicies: action.rolePolicies
			}
		case FETCH_ROLE_POLICIES_FAILURE:
			return {
				...state,
				loading: false
			}
		case FETCH_POLICIES_BEGIN:
			return {
				...state,
				loading: true,
				policies: []
			}
		case FETCH_POLICIES_SUCCESS: 
			return {
				...state,
				loading: false,
				policies: action.policies.filter(policy => {
					return policy.charAt(0) !== "-";
				})
			}
		case FETCH_POLICIES_FAILURE:
			return {
				...state,
				loading: false
			}
		case ADD_EDIT_EMPLOYEE_BEGIN:
			return {
				...state,
				loading: true
			}
		case ADD_EDIT_EMPLOYEE_SUCCESS:
			let employees = [...state.employees];
			let existingID = 0;
			for (let i = 0; i < employees.length; i++) {
				if (employees[i].ID === action.employee.ID) {
					employees[i] = action.employee;
					existingID = action.employee.ID;
				}
			}
			if (existingID > 0) { // update existing
				return { ...state, loading: false, employees }
			} else { // add new
				return { ...state, loading: false, employees: [...state.employees, action.employee] }
			}
		case ADD_EDIT_EMPLOYEE_FAILURE:
			return {
                ...state,
                loading: false
            }
		case ADD_EDIT_ROLE_BEGIN:
			return {
				...state,
				loading: true
			}
		case ADD_EDIT_ROLE_SUCCESS:
			var roles = { ...state.roles };
			roles[action.role] = action.policies
			return{
				...state,
				loading: false,
				roles
			}
		case ADD_EDIT_ROLE_FAILURE:
			return {
                ...state,
                loading: false
            }
		case DELETE_EMPLOYEE_BEGIN:
			return {
				...state,
				loading: true
			}
		case DELETE_EMPLOYEE_SUCCESS:
			return {
				...state,
                loading: false,
				employees: state.employees.filter((employee) => {
					return employee.ID !== action.employee.ID;
				})
			}
		case DELETE_EMPLOYEE_FAILURE:
			return {
                ...state,
                loading: false
            }
		case DELETE_ROLE_BEGIN:
			return {
				...state,
				loading: true
			}
		case DELETE_ROLE_SUCCESS:
			var roles = { ...state.roles };
			const { [action.role]: value, ...newRoles } = state.roles;
			return{
				...state,
				loading: false,
				roles: newRoles
			}
		case DELETE_ROLE_FAILURE:
			return {
                ...state,
                loading: false
            }
		case RESET_PASSWORD_SELF_BEGIN:
			return {
				...state,
				resetPasswordSelfLoading: true
			}
		case RESET_PASSWORD_SELF_SUCCESS:
			return {
				...state,
				resetPasswordSelfLoading: false,
				resetPasswordSelfError: ''
			}
		case RESET_PASSWORD_SELF_FAILURE:
			return {
				...state,
				resetPasswordSelfLoading: false,
				resetPasswordSelfError: action.error
			}

		default:
			return state;
	}
	
}

export default employee
