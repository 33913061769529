import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import EmployeeMgr from './EmployeeMgr';
import MTLS from './MTLS';
import msg from './Msg';

const reducers = combineReducers({
    msg,
    theme: Theme,
    auth: Auth,
    employee: EmployeeMgr,
    mtls: MTLS
});

export default reducers;
