import {
	AUTH_TOKEN,
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	FETCH_MODULES_SUCCESS
} from '../constants/Auth';

const getPolicyMap = (token) => {
	let policies = {};
	if (token && token.token_policies) {
		token.token_policies.forEach(policy => {
			policies[policy] = true;
		});
	}
	return policies;
}

const token = localStorage.getItem(AUTH_TOKEN) ?
	JSON.parse(localStorage.getItem(AUTH_TOKEN)) : null

var profile = {};
if (token) {
	profile = JSON.parse(token.metadata.profile);
}

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  pwExpired: false,
  pwExpires: "",
  token, // vault token
  profile, // employee profile
  modules: {}, // running modules / applications map
  policies: getPolicyMap(token) // token policy map
}

const auth = (state = initState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
			var profile = {};
			try {
				profile = JSON.parse(action.token.metadata.profile);
			} catch(e) {}
			return {
				...state,
				loading: false,
				pwExpired: action.pwExpired,
				pwExpires: action.pwExpires,
				token: action.token,
				profile,
				policies: getPolicyMap(action.token)
			}
		case FETCH_MODULES_SUCCESS: 
			return {
				...state,
				modules: action.modules
			}
		case SHOW_AUTH_MESSAGE: 
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNOUT: {
			return {
				...state,
				loading: false
			}
		}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				loading: false
			}
		}
		case SIGNUP_SUCCESS: {
			return {
			  ...state,
			  loading: false,
			  token: action.token
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		default:
			return state;
	}
}

export default auth