import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import history from '../history'
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { notification } from 'antd';
import { v4 as uuidv4 } from 'uuid';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
})

// Config
const ENTRY_ROUTE = '/auth/login'
const REQUEST_ID_KEY = 'X-Request-ID'
const TOKEN_PAYLOAD_KEY = 'X-Vault-Token'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(config => {
  let vaultToken = null;
  const vaultTokenJSON = localStorage.getItem(AUTH_TOKEN)
  if (vaultTokenJSON) {
	vaultToken = JSON.parse(vaultTokenJSON)
  }
	
  if (vaultToken) {
    config.headers[TOKEN_PAYLOAD_KEY] = vaultToken ?
		vaultToken.client_token : ''
  }

  if (!vaultToken && !config.headers[PUBLIC_REQUEST_KEY]) {
		history.push(ENTRY_ROUTE)
		window.location.reload();
  }
  config.headers[REQUEST_ID_KEY] = uuidv4();
  return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
  Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (res) => {
	return res.data
}, (error) => {
	console.log("api result error:", error.toString())
	let notificationParam = {
		message: ''
	}
	if(error.toString()=="Error: Network Error"){
		console.log("notificationParam.message = 'Internal Server Error'");
		notificationParam.message = 'Internal Server Error'
		notification.error(notificationParam)
	}else if (error.response.status === 400 || error.response.status === 401 || error.response.status === 403) {
		// notificationParam.message = 'Authentication Fail'
		// notificationParam.description = 'Please login again'
		localStorage.removeItem(AUTH_TOKEN)
		history.push(ENTRY_ROUTE)
		window.location.reload();
	} else if (error.response.status === 404) {
		notificationParam.message = 'Not Found'
		notification.error(notificationParam)
	} else if (error.response.status === 500) {
		notificationParam.message = 'Internal Server Error'
		notification.error(notificationParam)
	} else if (error.response.status === 508) {
		notificationParam.message = 'Time Out'
		notification.error(notificationParam)
	}

	return Promise.reject(error);
});

export default service