import fetch from 'auth/FetchInterceptor';
import { API_BASE_URL } from 'configs/AppConfig'
import { data } from 'autoprefixer';
import axios from 'axios';

const basePath = '/employee-manager';
const headerPWExpired = "x-password-expired";
const headerPWExpires = "x-password-expires";

const employeeMgrService = {};

employeeMgrService.login = (data) => {
  let url = API_BASE_URL + basePath + "/login"
  let basicAuth = `Basic ${Buffer.from(`${data.user}:${data.password}`).toString('base64')}`;
  return axios.post(url, data, {
		headers: {
      'Content-Type': 'application/json',
      'Authorization': basicAuth,
      'public-request': 'true'
    }
	}).then(res => {
    // console.log("Login Response Headers:", res);
    let pwExpired = false;
    let pwExpires = "";
    for (let h in res.headers) {
      let header = h.toLowerCase();
      console.log(header, " => ", res.headers[h])
      if (header === headerPWExpired) {
        pwExpired = res.headers[h] === "true";
      } else if (header === headerPWExpires) {
        pwExpires = res.headers[h];
      }
    }
    return { data: res.data, pwExpired, pwExpires }
  });
}

employeeMgrService.getEmployees = () => {
  return fetch({
    url: `${basePath}/employee`,
    method: 'get'
  })
}

employeeMgrService.getPasswordRules = () => {
  return fetch({
    url: `${basePath}/employee/password-rules`,
    method: 'get'
  })
}

employeeMgrService.getRoles = () => {
  return fetch({
    url: `${basePath}/roles`,
    method: 'get'
  })
}

employeeMgrService.getRolePolicies = (role) => {
  return fetch({
    url: `${basePath}/roles/${role}`,
    method: 'get',
    data: role
  })
}

employeeMgrService.getPolicies = () => {
  return fetch({
    url: `${basePath}/policies`,
    method: 'get'
  })
}

employeeMgrService.addEditEmployee = (employee) => {
  return fetch({
    url: `${basePath}/employee`,
    method: 'post',
    data: employee
  })
}

employeeMgrService.addEditRole = (role, policies) => {
  return fetch({
    url: `${basePath}/roles/${role}`,
    method: 'post',
    data: policies
  })
}

employeeMgrService.deleteEmployee = (employee) => {
  return fetch({
    url: `${basePath}/employee/${employee}`,
    method: 'delete',
    data: employee.ID
  })
}

employeeMgrService.deleteRole = (role) => {
  return fetch({
    url: `${basePath}/roles/${role}`,
    method: 'delete',
  })
}

employeeMgrService.updatePassword = (data) => {
  return fetch({
    url: `${basePath}/employee/password-reset/self`,
    method: 'post',
    data: data
  })
}

employeeMgrService.resetPasswordAny = (employeeID, data) => {
  return fetch({
    url: `${basePath}/employee/password-reset/any/${employeeID}`,
    method: 'post',
    data: data
  })
}

employeeMgrService.resetPasswordSelf = (data) => {
  return fetch({
    url: `${basePath}/employee/password-reset/self`,
    method: 'post',
    data: data
  })
}

export default employeeMgrService
