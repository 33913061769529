import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Alert } from "antd";
import { WarningOutlined } from "@ant-design/icons"
import moment from "moment";

const DAYS_TO_NOTIFY = 30;
const iconStyle = { position: 'relative', top: -3 }

export const PWAlert = ({ pwExpired, pwExpires }) => {

    if (!pwExpires) { // will be blank if running locally
        return null;
    }

    let messageDetails = null
    if (pwExpired) {
        messageDetails = <span>Your password is expired.</span>;
    } else {
        const now = moment();
        let tExpires = moment(pwExpires);
        if (!tExpires.isValid()) {
            console.log("pw expiration time invalid: ", pwExpires);
            return null;
        }
        let dayDiff = tExpires.diff(now, "d");
        if (!pwExpired && dayDiff > DAYS_TO_NOTIFY) {
            return null;
        }
        messageDetails = <span>
            Your password expires in {moment.duration(tExpires.diff(now)).humanize()}.
        </span>;
    }

    let message = <div>
        <WarningOutlined style={{ marginRight: 10, ...iconStyle }} />
        <b>{ messageDetails }</b>&nbsp;&nbsp;
        Go to your <Link to="/app/edit-profile">profile page</Link> to update your password.
    </div>
  
    return <Alert
        style={{ marginBottom: 20 }}
        type="warning"
        message={message}
        closable
    />;
}

const mapStateToProps = ({ auth }) => {
  const { pwExpired, pwExpires } = auth;
  return { pwExpired, pwExpires }
};

export default connect(mapStateToProps)(React.memo(PWAlert));
