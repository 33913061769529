import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import * as c from '../constants/MTLS';
import * as a from 'redux/actions/MTLS';
import MTLSService from 'services/MTLSService'
import { notify } from 'redux/actions/Msg';

// certs

export function* watchFetchCertsBegin() {
	yield takeEvery(c.FETCH_CERTS_BEGIN, function*() {
		const res = yield call(MTLSService.fetchCerts)
		yield put(a.fetchCertsSuccess(res))
	})
}

export function* watchAddCertBegin() {
	yield takeEvery(c.ADD_CERT_BEGIN, function*(action) {
		const { res, err } = yield call(MTLSService.addCert,
			action.payload.pem, action.payload.policies);
		if (res) {
			yield put(a.addCertSuccess(res))
			yield put(notify({
				message: 'Certificate added.',
				description: ''
			}))
		} else {
			yield put(a.addCertFailure(err))
		}
	})
}

export function* watchUpdateCertBegin() {
	yield takeEvery(c.UPDATE_CERT_BEGIN, function*(action) {
		const { res, err } = yield call(MTLSService.updateCert,
			action.payload.fingerprint, action.payload.policies);
		if (res) {
			yield put(a.updateCertSuccess(res))
			yield put(notify({
				message: 'Certificate updated.',
				description: ''
			}))
		} else {
			yield put(a.updateCertFailure(err))
		}
	})
}

export function* watchDelCertBegin() {
	yield takeEvery(c.DELETE_CERT_BEGIN, function*(action) {
		const { res, err } = yield call(MTLSService.deleteCert,
			action.fingerprint);
		if (err) {
			yield put(a.delCertFailure(err))
		} else {
			yield put(a.delCertSuccess(action.fingerprint))
			yield put(notify({
				message: 'Certificate deleted.',
				description: ''
			}))
		}
	})
}

// uuid

export function* watchFetchUUIDBegin() {
	yield takeEvery(c.FETCH_UUID_BEGIN, fetchUUID)
}

export function* fetchUUID() {
	const res = yield call(MTLSService.uuid)
    yield put(a.fetchUUIDSuccess(res.uuid))
}

export default function* rootSaga() {
  yield all([
		fork(watchFetchCertsBegin),
		fork(watchAddCertBegin),
		fork(watchUpdateCertBegin),
		fork(watchDelCertBegin),
		fork(watchFetchUUIDBegin)
  ]);
}
