import {
	all,
	takeEvery,
	put,
	fork,
	call
} from 'redux-saga/effects';

import { notify } from 'redux/actions/Msg';

import { FETCH_EMPLOYEES_BEGIN, FETCH_PASSWORD_RULES_BEGIN, FETCH_ROLES_BEGIN, FETCH_ROLE_POLICIES_BEGIN, FETCH_POLICIES_BEGIN, 
	ADD_EDIT_EMPLOYEE_BEGIN, ADD_EDIT_ROLE_BEGIN, DELETE_EMPLOYEE_BEGIN, DELETE_ROLE_BEGIN,
    RESET_PASSWORD_SELF_BEGIN } from '../constants/EmployeeMgr';
import { fetchEmployeesSuccess, fetchPasswordRulesSuccess, fetchRolesSuccess, fetchRolePoliciesSuccess, fetchPoliciesSuccess, 
	addEditEmployeeSuccess, addRoleSuccess, deleteEmployeeSuccess, deleteRoleSuccess,
    resetPasswordSelfSuccess, resetPasswordSelfFailure } from 'redux/actions/EmployeeMgr';

import EmployeeMgrService from 'services/EmployeeMgrService'

// ------------------------------------------------------------------------------------------------- //

export function* watchFetchEmployees() {
	yield takeEvery(FETCH_EMPLOYEES_BEGIN, fetchEmployees)
}

export function* fetchEmployees() {
	const employees = yield call(EmployeeMgrService.getEmployees)
    yield put(fetchEmployeesSuccess(employees))
}

// ------------------------------------------------------------------------------------------------- //

export function* watchFetchPasswordRules() {
	yield takeEvery(FETCH_PASSWORD_RULES_BEGIN, fetchPasswordRules)
}

export function* fetchPasswordRules() {
	const passwordRules = yield call(EmployeeMgrService.getPasswordRules)
    yield put(fetchPasswordRulesSuccess(passwordRules))
}

// ------------------------------------------------------------------------------------------------- //

export function* watchFetchRoles() {
	yield takeEvery(FETCH_ROLES_BEGIN, fetchRoles)
}

export function* fetchRoles() {
	const roles = yield call(EmployeeMgrService.getRoles)
    yield put(fetchRolesSuccess(roles))
}

// ------------------------------------------------------------------------------------------------- //

export function* watchFetchRolePolicies() {
	yield takeEvery(FETCH_ROLE_POLICIES_BEGIN, fetchRolePolicies)
}

export function* fetchRolePolicies(action) {
	const rolePolicies = yield call(EmployeeMgrService.getRolePolicies, action.rolePolicies)
    yield put(fetchRolePoliciesSuccess(rolePolicies))
}

// ------------------------------------------------------------------------------------------------- //

export function* watchFetchPolicies() {
	yield takeEvery(FETCH_POLICIES_BEGIN, fetchPolicies)
}

export function* fetchPolicies() {
	const policies = yield call(EmployeeMgrService.getPolicies)
    yield put(fetchPoliciesSuccess(policies))
}

// ------------------------------------------------------------------------------------------------- //

export function* watchAddEditEmployee() {
	yield takeEvery(ADD_EDIT_EMPLOYEE_BEGIN, addEditEmployee)
}

export function* addEditEmployee(action) {
	const empl = yield call(EmployeeMgrService.addEditEmployee, action.employee)
    yield put(addEditEmployeeSuccess(empl))
}

// ------------------------------------------------------------------------------------------------- //

export function* watchAddEditRole() {
	yield takeEvery(ADD_EDIT_ROLE_BEGIN, addEditRole)
}

export function* addEditRole(action) {
	yield call(EmployeeMgrService.addEditRole, action.role, action.policies)
    yield put(addRoleSuccess(action.role, action.policies))
}

// ------------------------------------------------------------------------------------------------- //

export function* watchDeleteEmployee() {
	yield takeEvery(DELETE_EMPLOYEE_BEGIN, deleteEmployee)
}

export function* deleteEmployee(action) {
	yield call(EmployeeMgrService.deleteEmployee, action.employee.ID)
    yield put(deleteEmployeeSuccess(action.employee))
}

// ------------------------------------------------------------------------------------------------- //

export function* watchDeleteRole() {
	yield takeEvery(DELETE_ROLE_BEGIN, deleteRole)
}

export function* deleteRole(action) {
	yield call(EmployeeMgrService.deleteRole, action.role)
    yield put(deleteRoleSuccess(action.role))
}

// ------------------------------------------------------------------------------------------------- //

export function* watchPasswordResetSelf() {
	yield takeEvery(RESET_PASSWORD_SELF_BEGIN, function*(action) {
		try {
			yield call(EmployeeMgrService.resetPasswordSelf, action.pw)
			yield put(resetPasswordSelfSuccess())
			yield put(notify({
				message: 'Password updated.',
				description: ''
			}))
		} catch (e) {
			yield put(resetPasswordSelfFailure(e))
		}
	})
}

// ------------------------------------------------------------------------------------------------- //

export default function* rootSaga() {
  yield all([
		fork(watchFetchEmployees),
		fork(watchAddEditEmployee),
		fork(watchDeleteEmployee),
		fork(watchFetchPasswordRules),
		fork(watchFetchRoles),
		fork(watchFetchPolicies),
		fork(watchDeleteRole),
		fork(watchAddEditRole),
		fork(watchPasswordResetSelf),
  ]);
}
