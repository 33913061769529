import fetch from 'auth/FetchInterceptor'
import { API_BASE_URL } from 'configs/AppConfig'
import axios from 'axios'

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000
})

const basePath = '/nex7/bc-app-repository'

const appRepoService = {}

appRepoService.fetchModules = () => {
  return service.get(`${basePath}/clients/`)
    .then(res => {
      let modules = {};
      if (res && res.data && res.data.length > 0) {
        try {
          // right now we expect 1 client,
          // and that client is the first floor system
          let ff = res.data[0];
          ff.Summaries.sums.forEach(sum => {
            modules[sum.app_name] = sum.app_version;
          })
        } catch (e) {
          console.log('error getting modules: ', e)
        }
      }
      console.log('modules: ', modules)
      return modules
    })
    .catch(err => {
      return err
    })
}

export default appRepoService
