import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/employee`} component={lazy(() => import(`./employees`))} />
        <Route path={`${APP_PREFIX_PATH}/role`} component={lazy(() => import(`./roles`))} />
        <Route path={`${APP_PREFIX_PATH}/certs`} component={lazy(() => import(`./certs`))} />
        <Route path={`${APP_PREFIX_PATH}/reports`} component={lazy(() => import(`./reports`))} />
        <Route path={`${APP_PREFIX_PATH}/edit-profile`} component={lazy(() => import(`./edit-profile`))} />
        <Route path={`${APP_PREFIX_PATH}/bc-apps`} component={lazy(() => import(`./bc-apps`))} />
        <Route path={`${APP_PREFIX_PATH}/bc-clients`} component={lazy(() => import(`./bc-clients`))} />

        <Route path={`${APP_PREFIX_PATH}/fdn/blenders`} component={lazy(() => import(`./fdn/blenders`))} />
        <Route path={`${APP_PREFIX_PATH}/fdn/certs`} component={lazy(() => import(`./fdn/certs`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);