export const FETCH_CERTS_BEGIN = 'FETCH_CERTS_BEGIN';
export const FETCH_CERTS_SUCCESS = 'FETCH_CERTS_SUCCESS';
export const FETCH_CERTS_FAILURE = 'FETCH_CERTS_FAILURE';

export const ADD_CERT_BEGIN = 'ADD_CERT_BEGIN';
export const ADD_CERT_SUCCESS = 'ADD_CERT_SUCCESS';
export const ADD_CERT_FAILURE = 'ADD_CERT_FAILURE';

export const UPDATE_CERT_BEGIN = 'UPDATE_CERT_BEGIN';
export const UPDATE_CERT_SUCCESS = 'UPDATE_CERT_SUCCESS';
export const UPDATE_CERT_FAILURE = 'UPDATE_CERT_FAILURE';

export const DELETE_CERT_BEGIN = 'DELETE_CERT_BEGIN';
export const DELETE_CERT_SUCCESS = 'DELETE_CERT_SUCCESS';
export const DELETE_CERT_FAILURE = 'DELETE_CERT_FAILURE';

export const FETCH_UUID_BEGIN = 'FETCH_UUID_BEGIN';
export const FETCH_UUID_SUCCESS = 'FETCH_UUID_SUCCESS';
export const FETCH_UUID_FAILURE = 'FETCH_UUID_FAILURE';
