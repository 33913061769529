import * as c from '../constants/MTLS';

/*
 *  Certificate actions
 */

export const fetchCerts = () => {
  return {
    type: c.FETCH_CERTS_BEGIN
  }
};

export const fetchCertsSuccess = (certs) => {
  return {
    type: c.FETCH_CERTS_SUCCESS,
    certs
  }
};

export const fetchCertsFailure = (error) => {
  return {
    type: c.FETCH_CERTS_FAILURE,
    error
  }
};

export const addCert = (payload) => {
  return {
    type: c.ADD_CERT_BEGIN,
    payload
  }
};

export const addCertSuccess = (cert) => {
  return {
    type: c.ADD_CERT_SUCCESS,
    cert
  }
};

export const addCertFailure = (err) => {
  return {
    type: c.ADD_CERT_FAILURE,
    err
  }
};

export const updateCert = (payload) => {
  return {
    type: c.UPDATE_CERT_BEGIN,
    payload
  }
};

export const updateCertSuccess = (cert) => {
  return {
    type: c.UPDATE_CERT_SUCCESS,
    cert
  }
};

export const updateCertFailure = (err) => {
  return {
    type: c.UPDATE_CERT_FAILURE,
    err
  }
};

export const delCert = (fingerprint) => {
  return {
    type: c.DELETE_CERT_BEGIN,
    fingerprint
  }
};

export const delCertSuccess = (fingerprint) => {
  return {
    type: c.DELETE_CERT_SUCCESS,
    fingerprint
  }
};

export const delCertFailure = (err) => {
  return {
    type: c.DELETE_CERT_FAILURE,
    err
  }
};

/*
 *  UUID actions
 */

export const fetchUUID = () => {
  return {
    type: c.FETCH_UUID_BEGIN
  }
};

export const fetchUUIDSuccess = (uuid) => {
  return {
    type: c.FETCH_UUID_SUCCESS,
    uuid
  }
};

export const fetchUUIDFailure = (error) => {
  return {
    type: c.FETCH_UUID_FAILURE,
    error
  }
};
