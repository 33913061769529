import { NOTIFICATION } from 'redux/constants/Msg';

const initState = {
  notification: null,
  apiError: null
}

const msg = (state = initState, action) => {
	switch (action.type) {
		case NOTIFICATION:
			return {
				...state,
				notification: action.msg
			}
		default:
			return state;
	}
	
}

export default msg
