import { 
  // DashboardOutlined,
  FileDoneOutlined,
  FundViewOutlined,
  HomeOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
  {
    key: 'admin',
    path: `${APP_PREFIX_PATH}/admin`,
    title: 'Admin',
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'home',
        path: `${APP_PREFIX_PATH}/home`,
        title: 'Home',
        icon: HomeOutlined,
        breadcrumb: true,
        submenu: []
      }, {
        key: 'reports',
        path: `${APP_PREFIX_PATH}/reports`,
        title: 'Reports',
        icon: FundViewOutlined,
        module: 'reports',
        breadcrumb: true,
        submenu: []
      },{
        key: 'edit-profile',
        path: `${APP_PREFIX_PATH}/edit-profile`,
        title: 'Employee Profile',
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'admin',
        path: `${APP_PREFIX_PATH}/admin`,
        title: 'Admin',
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'certificates',
            path: `${APP_PREFIX_PATH}/certs`,
            title: 'Certificates',
            icon: FileDoneOutlined,
            module: 'mtls',
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'employees',
            path: `${APP_PREFIX_PATH}/employee`,
            title: 'Employees',
            icon: UserOutlined,
            module: 'employee-manager',
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'role',
            path: `${APP_PREFIX_PATH}/role`,
            title: 'Roles',
            icon: FileDoneOutlined,
            module: 'employee-manager',
            breadcrumb: true,
            submenu: []
          },
        ]
      },
      {
        key: 'admin_bc',
        path: `${APP_PREFIX_PATH}/admin_bc`,
        title: 'APP Manager',
        icon: FundViewOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'BC APPS',
            path: `${APP_PREFIX_PATH}/bc-apps`,
            title: 'BC apps',
            icon: FileDoneOutlined,
            module: 'bc-app-repository',
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'BC CLIENTS',
            path: `${APP_PREFIX_PATH}/bc-clients`,
            title: 'BC CLIENTS',
            icon: FileDoneOutlined,
            module: 'bc-app-repository',
            breadcrumb: true,
            submenu: []
          },
        ]
      },
      {
        key: 'foundation',
        path: `${APP_PREFIX_PATH}/fdn`,
        title: 'Foundation',
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'blenders',
            path: `${APP_PREFIX_PATH}/fdn/blenders`,
            title: 'Blenders',
            icon: SettingOutlined,
            module: 'employee-manager',
            module: 'vault-initializer',
            breadcrumb: true,
            submenu: []
          }, {
            key: 'certs',
            path: `${APP_PREFIX_PATH}/fdn/certs`,
            title: 'Certificates',
            icon: FileDoneOutlined,
            module: 'employee-manager',
            module: 'vault-initializer',
            breadcrumb: true,
            submenu: []
          }
        ]
      }
    ]
  }
]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
