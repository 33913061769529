import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons"
import { connect } from 'react-redux'
import { 
  EditOutlined, 
  SettingOutlined, 
  ShopOutlined, 
  QuestionCircleOutlined, 
  LogoutOutlined 
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';

const menuItem = [
	{
		title: "Reset Password",
		icon: EditOutlined ,
		path: "/app/edit-profile"
  }
]

export const NavProfile = (props,{signOut}) => {
  console.log("props user:",props.token.metadata.profile)
  let userProfile = JSON.parse(props.token.metadata.profile)
  const profileImg = "/img/avatars/thumb-1.jpg";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div className="pl-3">
            <h4 className="mb-0">admin</h4>
            <span className="text-muted">{/*employee number*/}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <div className="drop-userinfo">
          <div className="mb-1">FirstName: {userProfile.first_name}</div>
          <div className="mb-1">LastName: {userProfile.last_name}</div>
          <div className="mb-1">UserName: {userProfile.username}</div>
          <div className="mb-1">Email:{userProfile.email}</div>
        </div>
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <UserOutlined style={{ fontSize: 24, marginTop: 25 }} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

const mapStateToProps = ({ auth }) => {
	const { token } = auth
	return { token }
}

export default connect(mapStateToProps, {signOut})(NavProfile)
