import { all } from 'redux-saga/effects';
import Auth from './Auth';
import MTLS from './MTLS';
import EmployeeMgr from './EmployeeMgr';

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    MTLS(),
    EmployeeMgr()
  ]);
}
