import {
  FETCH_EMPLOYEES_BEGIN,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEES_FAILURE,
  
  FETCH_PASSWORD_RULES_BEGIN,
  FETCH_PASSWORD_RULES_SUCCESS,
  FETCH_PASSWORD_RULES_FAILURE,

  FETCH_ROLES_BEGIN,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILURE,

  FETCH_ROLE_POLICIES_BEGIN,
  FETCH_ROLE_POLICIES_SUCCESS,
  FETCH_ROLE_POLICIES_FAILURE,

  FETCH_POLICIES_BEGIN,
  FETCH_POLICIES_SUCCESS,
  FETCH_POLICIES_FAILURE,

  ADD_EDIT_EMPLOYEE_BEGIN,
  ADD_EDIT_EMPLOYEE_SUCCESS,
  ADD_EDIT_EMPLOYEE_FAILURE,

  ADD_EDIT_ROLE_BEGIN,
  ADD_EDIT_ROLE_SUCCESS,
  ADD_EDIT_ROLE_FAILURE,
  
  DELETE_EMPLOYEE_BEGIN,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,

  DELETE_ROLE_BEGIN,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,

  RESET_PASSWORD_SELF_BEGIN,
  RESET_PASSWORD_SELF_SUCCESS,
  RESET_PASSWORD_SELF_FAILURE
} from '../constants/EmployeeMgr';

export const fetchEmployees = () => {
  return {
    type: FETCH_EMPLOYEES_BEGIN
  }
};

export const fetchEmployeesSuccess = (employees) => {
  return {
    type: FETCH_EMPLOYEES_SUCCESS,
    employees
  }
};

export const fetchEmployeesFailure = (error) => {
  return {
    type: FETCH_EMPLOYEES_FAILURE,
    error
  }
};

export const fetchPasswordRules = () => {
  return {
    type: FETCH_PASSWORD_RULES_BEGIN
  }
};

export const fetchPasswordRulesSuccess = (passwordRules) => {
  return {
    type: FETCH_PASSWORD_RULES_SUCCESS,
    passwordRules
  }
};

export const fetchPasswordRulesFailure = (error) => {
  return {
    type: FETCH_PASSWORD_RULES_FAILURE,
    error
  }
};

export const fetchRoles = () => {
  return {
    type: FETCH_ROLES_BEGIN
  }
};

export const fetchRolesSuccess = (roles) => {
  return {
    type: FETCH_ROLES_SUCCESS,
    roles
  }
};

export const fetchRolesFailure = (error) => {
  return {
    type: FETCH_ROLES_FAILURE,
    error
  }
};

export const fetchRolePolicies = (rolePolicies) => {
  return {
    type: FETCH_ROLE_POLICIES_BEGIN,
    rolePolicies
  }
};

export const fetchRolePoliciesSuccess = (rolePolicies) => {
  return {
    type: FETCH_ROLE_POLICIES_SUCCESS,
    rolePolicies
  }
};

export const fetchRolePoliciesFailure = (error) => {
  return {
    type: FETCH_ROLE_POLICIES_FAILURE,
    error
  }
};

export const fetchPolicies = () => {
  return {
    type: FETCH_POLICIES_BEGIN
  }
};

export const fetchPoliciesSuccess = (policies) => {
  return {
    type: FETCH_POLICIES_SUCCESS,
    policies
  }
};

export const fetchPoliciesFailure = (error) => {
  return {
    type: FETCH_POLICIES_FAILURE,
    error
  }
};

export const addEditEmployee = (employee) => {
  return {
    type: ADD_EDIT_EMPLOYEE_BEGIN,
    employee
  }
};

export const addEditEmployeeSuccess = (employee) => {
  return {
    type: ADD_EDIT_EMPLOYEE_SUCCESS,
    employee
  }
};

export const addEditEmployeeFailure = (error) => {
  return {
    type: ADD_EDIT_EMPLOYEE_FAILURE,
    error
  }
};

export const addEditRole = (role, policies) => {
  return {
    type: ADD_EDIT_ROLE_BEGIN,
    role, policies
  }
};

export const addRoleSuccess = (role, policies) => {
  return {
    type: ADD_EDIT_ROLE_SUCCESS,
    role, policies
  }
};

export const addRoleFailure = (error) => {
  return {
    type: ADD_EDIT_ROLE_FAILURE,
    error
  }
};

export const deleteEmployee = (employee) => {
  return {
    type: DELETE_EMPLOYEE_BEGIN,
    employee
  }
};

export const deleteEmployeeSuccess = (employee) => {
  return {
    type: DELETE_EMPLOYEE_SUCCESS,
    employee
  }
};

export const deleteEmployeeFailure = (error) => {
  return {
    type: DELETE_EMPLOYEE_FAILURE,
    error
  }  
};

export const deleteRole = (role) => {
  return {
    type: DELETE_ROLE_BEGIN,
    role
  }
};

export const deleteRoleSuccess = (role) => {
  return {
    type: DELETE_ROLE_SUCCESS,
    role
  }
};

export const deleteRoleFailure = (error) => {
  return {
    type: DELETE_ROLE_FAILURE,
    error
  }  
};

export const resetPasswordSelf = (pw) => {
  return {
    type: RESET_PASSWORD_SELF_BEGIN,
    pw
  }
};

export const resetPasswordSelfSuccess = () => {
  return {
    type: RESET_PASSWORD_SELF_SUCCESS
  }
};

export const resetPasswordSelfFailure = (error) => {
  return {
    type: RESET_PASSWORD_SELF_FAILURE,
    error
  }  
};
