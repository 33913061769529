import fetch from 'auth/FetchInterceptor'

const basePath = '/mtls'

const mtlsService = {}

mtlsService.uuid = () => {
  return fetch({
    url: `${basePath}/uuid`,
    method: 'get',
    headers: {
      'public-request': 'true'
    }
  })
}

mtlsService.fetchCerts = () => {
  return fetch({
    url: `${basePath}/conf/crt`,
    method: 'get'
  })
}

mtlsService.addCert = (pem, policies) => {
  const data = { "PEM": pem, "Policies": policies };
  return fetch({
    url: `${basePath}/conf/crt`,
    method: 'post',
    data
  }).then(res => ({ res })).catch(err => ({ err }))
}

mtlsService.updateCert = (fingerprint, policies) => {
  console.log('fingerprint', fingerprint);
  console.log('policies', policies);
  return fetch({
    url: `${basePath}/conf/crt/${fingerprint}`,
    method: 'post',
    data: policies // string array of policies
  }).then(res => ({ res })).catch(err => ({ err }))
}

mtlsService.deleteCert = (fingerprint) => {
  return fetch({
    url: `${basePath}/conf/crt/${fingerprint}`,
    method: 'delete'
  }).then(res => ({ res })).catch(err => ({ err }))
}

export default mtlsService
