import {
	all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import {
	AUTH_TOKEN, FETCH_MODULES, SIGNOUT, AUTHENTICATED
} from '../constants/Auth';
import AppRepoService from 'services/AppRepoService'
import { fetchModulesSuccess } from 'redux/actions/Auth';

export function* signInWithEmployeeManager() {
	yield takeEvery(AUTHENTICATED, function* ({token}) {
		yield localStorage.setItem(AUTH_TOKEN, JSON.stringify(token))
	})
}

export function* fetchModules() {
	yield takeEvery(FETCH_MODULES, function* () {
		const modules = yield call(AppRepoService.fetchModules)
		yield put(fetchModulesSuccess(modules))
	})
}

export function* signOut() {
	yield takeEvery(SIGNOUT, function* () {
		yield localStorage.removeItem(AUTH_TOKEN);
		yield window.location.href = '/auth/login';
	})
}

export default function* rootSaga() {
  yield all([
		fork(signInWithEmployeeManager),
		fork(fetchModules),
		fork(signOut)
  ]);
}
