const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return {
				API_ENDPOINT_URL: 'https://ff1.dev.acres.red'
			};
		default:
			return {
				API_ENDPOINT_URL: `https://${window.location.hostname}`
			};
	}
}

export const env = getEnv()
