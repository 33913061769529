import Item from 'antd/lib/list/Item';
import * as c from 'redux/constants/MTLS';
import certs from 'views/app-views/certs';

const initState = {
  // certs
  certs: [],
  fetching: false,
  fetchingError: null,
  adding: false,
  addingError: null,
  updating: false,
  updatingError: null,
  deleting: false,
  deletingError: null,
  // uuid
  uuid: '',
  uuidError: null
}

const mtls = (state = initState, action) => {
	switch (action.type) {

		// certs - fetch
		case c.FETCH_CERTS_BEGIN:
			return {
				...state,
				fetching: true,
				certs: []
			}
		case c.FETCH_CERTS_SUCCESS: 
			return {
				...state,
                fetching: false,
				certs: action.certs
			}
        case c.FETCH_CERTS_FAILURE:
            return {
                ...state,
                fetching: false,
				fetchingError: action.error
            }

		// certs - add
		case c.ADD_CERT_BEGIN:
			return {
				...state,
				adding: true
			}
		case c.ADD_CERT_SUCCESS:
			return {
				...state,
                adding: false,
				certs: [...state.certs, action.cert]
			}
        case c.ADD_CERT_FAILURE:
            return {
                ...state,
                adding: false,
				addingError: action.error
            }

		// certs - update
		case c.UPDATE_CERT_BEGIN:
			return {
				...state,
				updating: true
			}
		case c.UPDATE_CERT_SUCCESS:
			return {
				...state,
                updating: false,
				certs: state.certs.map(cert => {
					if (cert.Fingerprint !== action.cert.Fingerprint) {
						return cert;
					}
					return { ...cert, ...action.cert }
				})
			}
        case c.UPDATE_CERT_FAILURE:
            return {
                ...state,
                updating: false,
				updatingError: action.error
            }

		// certs - delete
		case c.DELETE_CERT_BEGIN:
			return {
				...state,
				deleting: true
			}
		case c.DELETE_CERT_SUCCESS:
			return {
				...state,
                deleting: false,
				certs: state.certs.filter((cert) => {
					return cert.Fingerprint !== action.fingerprint;
				})
			}
        case c.DELETE_CERT_FAILURE:
            return {
                ...state,
                deleting: false,
				deletingError: action.error
            }
		
		// uuid
		case c.FETCH_UUID_BEGIN:
			return {
				...state,
				uuid: ''
			}
		case c.FETCH_UUID_SUCCESS: 
			return {
				...state,
				uuid: action.uuid
			}
        case c.FETCH_UUID_FAILURE:
            return {
                ...state,
				uuidError: action.error
            }
		default:
			return state;
	}
}

export default mtls
