import {
  FETCH_MODULES,
  FETCH_MODULES_SUCCESS,
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SHOW_LOADING,
} from '../constants/Auth';

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user
  }
};

export const fetchModules = () => {
  return {
    type: FETCH_MODULES
  }
}

export const fetchModulesSuccess = (modules) => {
  return {
    type: FETCH_MODULES_SUCCESS,
    modules
  }
}

export const authenticated = (resp) => {
  return {
    type: AUTHENTICATED,
    token: resp.data,
    pwExpired: resp.pwExpired,
    pwExpires: resp.pwExpires
  }
};

export const signOut = () => {
  return {
    type: SIGNOUT
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  }
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
