// employees

export const FETCH_EMPLOYEES_BEGIN = 'FETCH_EMPLOYEES_BEGIN';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_FAILURE = 'FETCH_EMPLOYEES_FAILURE';

export const ADD_EDIT_EMPLOYEE_BEGIN = 'ADD_EDIT_EMPLOYEE_BEGIN';
export const ADD_EDIT_EMPLOYEE_SUCCESS = 'ADD_EDIT_EMPLOYEE_SUCCESS';
export const ADD_EDIT_EMPLOYEE_FAILURE = 'ADD_EDIT_EMPLOYEE_FAILURE';

export const DELETE_EMPLOYEE_BEGIN = 'DELETE_EMPLOYEE_BEGIN';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE';

// roles & policies

export const FETCH_ROLES_BEGIN = 'FETCH_ROLES_BEGIN';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE';

export const ADD_EDIT_ROLE_BEGIN = 'ADD_EDIT_ROLE_BEGIN';
export const ADD_EDIT_ROLE_SUCCESS = 'ADD_EDIT_ROLE_SUCCESS';
export const ADD_EDIT_ROLE_FAILURE = 'ADD_EDIT_ROLE_FAILURE';

export const DELETE_ROLE_BEGIN = 'DELETE_ROLE_BEGIN';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';

// TODO: remove
export const FETCH_ROLE_POLICIES_BEGIN = 'FETCH_ROLE_POLICIES_BEGIN';
export const FETCH_ROLE_POLICIES_SUCCESS = 'FETCH_ROLE_POLICIES_SUCCESS';
export const FETCH_ROLE_POLICIES_FAILURE = 'FETCH_ROLE_POLICIES_FAILURE';

// TODO: remove
export const FETCH_POLICIES_BEGIN = 'FETCH_POLICIES_BEGIN';
export const FETCH_POLICIES_SUCCESS = 'FETCH_POLICIES_SUCCESS';
export const FETCH_POLICIES_FAILURE = 'FETCH_POLICIES_FAILURE';

// password rules

export const FETCH_PASSWORD_RULES_BEGIN = 'FETCH_PASSWORD_RULES_BEGIN';
export const FETCH_PASSWORD_RULES_SUCCESS = 'FETCH_PASSWORD_RULES_SUCCESS';
export const FETCH_PASSWORD_RULES_FAILURE = 'FETCH_PASSWORD_RULES_FAILURE';

export const EDIT_PASSWORD_RULES_BEGIN = 'EDIT_PASSWORD_RULES_BEGIN';
export const EDIT_PASSWORD_RULES_SUCCESS = 'EDIT_PASSWORD_RULES_SUCCESS';
export const EDIT_PASSWORD_RULES_FAILURE = 'EDIT_PASSWORD_RULES_FAILURE';

// reset password

export const RESET_PASSWORD_SELF_BEGIN = 'RESET_PASSWORD_SELF_BEGIN';
export const RESET_PASSWORD_SELF_SUCCESS = 'RESET_PASSWORD_SELF_SUCCESS';
export const RESET_PASSWORD_SELF_FAILURE = 'RESET_PASSWORD_SELF_FAILURE';
