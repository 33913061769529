import React, { useState, useEffect } from 'react'
import { Table, Divider, Tag, Button, Modal, Input, Popconfirm, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { fetchCerts, addCert, updateCert, delCert } from 'redux/actions/MTLS';
import { fetchPolicies } from "redux/actions/EmployeeMgr"
import Moment from 'moment';

const iconStyle = { position: 'relative', top: -2 }

const { TextArea } = Input;
const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    return (
        <Tag color={"geekblue"} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
            {label}
        </Tag>
    );
};

const AddCertModal = (props) => {
    const { policies, certs, addCert } = props;
    const [showModal, setShowModal] = useState(false);
    const [certPolicies, setCertPolicies] = useState([]);
    const [pem, setPem] = useState('');

    useEffect(() => setShowModal(false), [certs]);

    return (
        <div>
            <Button type="primary" size="small" onClick={() => setShowModal(true)}>
                <PlusOutlined style={iconStyle} /> Add Certificate
            </Button>
            <Modal
                title="Add Certificate"
                transitionName={""}
                visible={showModal}
                onOk={() => addCert({ pem, policies: certPolicies })}
                onCancel={() => setShowModal(false)}
            >
                <p><strong>PEM:</strong></p>
                <TextArea rows={4} placeholder="PEM" onChange={(e) => setPem(e.target.value)} />
                <br /><br />
                <p><strong>Policies:</strong></p>
                <Select
                    mode="multiple"
                    tagRender={tagRender}
                    value={certPolicies}
                    style={{ width: '100%' }}
                    options={policies.map(policy => { return { value: policy }})}
                    onChange={(value) => setCertPolicies(value)}
                />
            </Modal>
        </div>
    );
}

const PolicyTags = (props) => {
    let color = 'geekblue';
    return <span>
        {
            props.policies.map(tag => {
                return (
                    <Tag color={color} key={tag}>
                        {tag.toUpperCase()}
                    </Tag>
                );
            })
        }
    </span>
}

const CertsTable = (props) => {
    const { certs, policies, updateCert, delCert } = props;
    const [selectedFingerprint, setSelectedFingerprint] = useState('');
    const [newPolicies, setNewPolicies] = useState([]);
    useEffect(() => {
        setSelectedFingerprint("");
        setNewPolicies([]);
    }, [certs]);
    
    const tableColumns = [
        {
            title: 'CN',
            dataIndex: 'CommonName',
            key: "cn",
            sorter: {
                compare: (a, b) => {
                    if (a.CommonName < b.CommonName) return -1;
                    if (b.CommonName < a.CommonName) return 1;
                    return 0;
                }
            },
        },
        {
            title: 'Expires',
            dataIndex: 'Expires',
            key: "exp",
            sorter: (a, b) => Moment(a.Expires).unix() - Moment(b.Expires).unix()
        },
        {
            title: 'Issuer CN',
            dataIndex: 'IssuerCN',
            key: "icn",
            sorter: {
                compare: (a, b) => {
                    if (a.IssuerCN < b.IssuerCN) return -1;
                    if (b.IssuerCN < a.IssuerCN) return 1;
                    return 0;
                }
            },
        },
        {
            title: 'Fingerprint',
            dataIndex: 'Fingerprint',
            key: "icn",
            sorter: {
                compare: (a, b) => {
                    if (a.Fingerprint < b.Fingerprint) return -1;
                    if (b.Fingerprint < a.Fingerprint) return 1;
                    return 0;
                }
            },
        },
        {
            title: 'Policies',
            dataIndex: 'Policies',
            key: "policies",
            render: (certPolicies, cert) => {
                return selectedFingerprint === cert.Fingerprint
                    ? <>
                        <Select
                            mode="multiple"
                            tagRender={tagRender}
                            value={newPolicies}
                            style={{ width: '100%' }}
                            options={policies.map(policy => { return { value: policy }})}
                            onChange={(value) => {
                                setNewPolicies(value);
                            }}
                        />
                        <div style={{ marginTop: 10 }}>
                            <Button variant="primary" size="small" onClick={() => {
                                updateCert({
                                    fingerprint: selectedFingerprint,
                                    policies: newPolicies
                                });
                            }}>
                                Save
                            </Button>&nbsp;
                            <Button variant="primary" size="small" onClick={() => {
                                setSelectedFingerprint('');
                                setNewPolicies([]);
                            }}>
                                Cancel
                            </Button>
                        </div>
                    </> : <>
                        <PolicyTags policies={certPolicies} />
                    </>
            }
        },
        {
            title: '',
            key: "action",
            render: (text, cert) => (
                <span>
                    <a onClick={() => {
                        setNewPolicies(cert.Policies)
                        setSelectedFingerprint(cert.Fingerprint)
                    }}>
                        Edit Policies
                    </a>
                    <Divider type="vertical" />
                    <Popconfirm
						placement="topLeft"
						title={"Delete " + cert.CommonName + "?"}
						onConfirm={() => {
							delCert(cert.Fingerprint);
						}}
						okText="Yes"
						cancelText="No">
						<a style={{ color: 'red' }}>Delete</a>
					</Popconfirm>
                </span>
            )
        },

    ];

    return <div>
        <Table columns={tableColumns} dataSource={certs} size="small"></Table>
    </div>
}

const Certs = (props) => {

    const { certs, fetchCerts, addCert, updateCert, delCert } = props;
    const { policies, fetchPolicies } = props;

    useEffect(() => {
        fetchCerts();
        fetchPolicies();
    }, []);

    return (
        <div>
            <p>These certificates are allowed to acquire tokens via GET requests to /mtls/token (mTLS authentication). The policies listed determine what a certificate is able to do on a cashless system.</p>
            <div style={{ float: "right", marginBottom: 20 }}>
                <AddCertModal certs={certs} policies={policies} addCert={addCert} />
            </div>
            <CertsTable certs={certs} policies={policies} updateCert={updateCert} delCert={delCert} />
        </div>
    )
}

const mapStateToProps = ({ mtls, employee }) => {
    const { certs } = mtls
    const { policies } = employee
    return { certs, policies }
}

const mapDispatchToProps = {
    fetchPolicies, fetchCerts, addCert, updateCert, delCert
}

export default connect(mapStateToProps, mapDispatchToProps)(Certs)
